import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy } from 'react';

const Admin = lazy(() => import('./pages/Admin'))
const Main = lazy(() => import('./pages/Main'))

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>
  },
  {
    path: "/985641997803987c65928a45eab2ed23b27b4ea1813b9ef52399f3f0ff5bafbd6396559c998549b59aad764c0e8897a9c15a3075ae3d2a3d710cdf8f2f959d412e6e0b94966a07a1fc08731daed5fb82e1fa3e04724bc08e160c768871178e2b9976e1248e407a3c65715f9f186acbc17cb6ebd4369904f6b2b0a433d4f54e24c883e7a959917981a48247f30ea02ef253809306bb203deb938b540dbdf0eb70",
    element: <Admin/>
  }
])

function App() {
  return (
    <RouterProvider router={ router }/>
  );
}

export default App;
